<template>
    <LiefengContent :isBack="$route.query.name || $route.query.isLineHome || $route.query.page ? true : false" @backClick="backClick">
        <template v-slot:title>
            网上家园管理居民审核
            <span @click.stop="changeEye">
                <Icon :type="hasSensitive ? 'md-eye-off' : 'md-eye'" />
            </span>
        </template>
        <template v-slot:toolsbarLeft>
            
        </template>
        <template v-slot:toolsbarRight>
            <Form :label-colon="true" :inline="true" class="search">
                <FormItem>
                    <Input :maxlength="20" enter-button v-model.trim="searchData.name" placeholder="姓名" style="width: 150px; margin-right: 10px" @on-enter="hadlePageSize" />
                </FormItem>
                <FormItem>
                    <Input :maxlength="20" enter-button v-model.trim="searchData.mobile" placeholder="手机号" style="width: 150px;margin-right: 10px" @on-enter="hadlePageSize" />
                </FormItem>
                <FormItem>
                    <Select transfer style="width:100px; margin-right: 10px" v-model="searchData.status" placeholder="请选择状态" clearable>
                        <Option v-for="(item, index) in statusList" :key="index" :value="item.value">{{ item.label }}</Option>
                    </Select>
                </FormItem>
                <Button style="margin: 0 10px 0 0" type="primary" icon="ios-search" @click="getList">查询</Button>
                <Button style="margin: 0 10px 0 0" type="success" icon="ios-refresh" @click="rest">重置</Button>
                <Button style="margin: 0 10px 0 0" type="primary" @click="througthMore">批量审核({{ num }})</Button>
                <Button style="margin: 0 10px 0 0" type="primary" @click="goSupplement">
                    补充房号处理
                    <span style="color:red">({{ handleNum }})</span>
                </Button>
                <!-- <Button
            style="margin: 0 5px 0 0"
            type="error"
            @click="delMore()"
            >批量删除<span style="color:res"> ({{num}})</span></Button
          > -->
                <!-- <Button
            @click="exportExcel"
            type="error"
            icon="ios-cloud-download-outline"
            >导出</Button
          > -->

                <Icon type="md-more" @click="selectQuery = true" style="font-size: 24px; line-height: 32px; cursor: pointer" title="更多查询条件" />
            </Form>

            <!-- <Drawer
          title="查询条件"
          v-model="selectQuery"
          width="320"
          :styles="{
            height: 'calc(100% - 55px)',
            overflow: 'auto',
            paddingBottom: '53px',
            position: 'static',
          }"
        >
          <Form :model="searchData" :label-colon="true">
            <FormItem label="姓名">
              <Input
                v-model.trim="searchData.name"
                placeholder="请输入姓名"
                :maxlength="20"
              ></Input>
            </FormItem>
            <FormItem label="手机号">
              <Input
                :maxlength="20"
                v-model.trim="searchData.phone"
                placeholder="请输入手机号"
              ></Input>
            </FormItem>
            <FormItem label="身份类型">
               <Select v-model="searchData.userType" placeholder="请选择身份类型" clearable>
                  <Option value="4">租赁人员</Option>
                  <Option value="3">是业主且户籍在此</Option>
                  <Option value="2">是业主但户籍不在此</Option>
                  <Option value="1">仅户籍在此</Option>
                  <Option value="5">其他</Option>
              </Select>
            </FormItem>
            <FormItem label="详细地址">
              <Input
                :maxlength="20"
                v-model.trim="searchData.detailAddr"
                placeholder="请输入详细地址"
                style="width: 100%"
              />
            </FormItem>
             <FormItem label="家庭联络人">
               <Select v-model="searchData.contact" placeholder="请选择是否家庭联络人" clearable>
                  <Option value="1">是</Option>
                  <Option value="2">否</Option>
              </Select>
            </FormItem>
          </Form>
          <div class="demo-drawer-footer">
            <Button style="margin-right: 8px" @click="selectQuery = false"
              >取消</Button
            >
            <Button type="primary" @click="getList">查询</Button>
          </div>
        </Drawer> -->
        </template>
        <template v-slot:contentArea>
            <LiefengTree ref="mychild" :fixedmenu="true" @handleData="getList" treeID="mychild" fixedContentID="contentarea" style="width: 200px" :idNode="idNode"></LiefengTree>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                height="200"
                :loading="loading"
                :fixTable="true"
                :curPage="curPage"
                :pagesizeOpts="[20, 30, 50, 100]"
                :total="total"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
                @tableSelect="tableSelect"
                class="table-div"
            ></LiefengTable>
            <!-- 驳回框 -->
            <LiefengModal title="居民信息驳回" width="30%" :value="rejectModal" @input="rejectModalFn">
                <template v-slot:contentarea>
                    <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">
                        <FormItem label="驳回原因" prop="reason">
                            <Input :maxlength="20" v-model.trim="formValidate.reason" placeholder="请输入驳回原因"></Input>
                        </FormItem>
                    </Form>
                </template>
                <template v-slot:toolsbar>
                    <Button type="info" @click="rejectModal = false" style="margin-right: 8px">取消</Button>
                    <Button type="primary" @click="rejectEvent('formValidate')">确认</Button>
                </template>
            </LiefengModal>

            <!-- 居民信息修改 -->
            <LiefengModal title="居民信息修改" width="600px" :value="detailModal" @input="detailModalFn">
                <template v-slot:contentarea>
                    <Form :model="userHomeInfo" style="margin-top:25px">
                        <FormItem label="所属社区:" style="width:450px">
                            <span>{{ userHomeInfo.orgName }}</span>
                        </FormItem>
                        <FormItem style="width:450px">
                            <span slot="label" class="validate">姓名:</span>
                            <Input placeholder="请输入您的真是姓名" :maxlength="20" v-model.trim="userHomeInfo.name" />
                        </FormItem>
                        <FormItem style="width:450px">
                            <span slot="label" class="validate">现住地址:</span>
                            <Input placeholder="请输入您的房号信息" :maxlength="20" enter-button="选择" v-model="userHomeInfo.addr" search @on-search="searchRoom" @on-change="changeHouseAddr" />
                        </FormItem>
                        <FormItem style="width:450px">
                            <span slot="label" class="validate">与住址关系:</span>
                            <Select clearable v-model="userHomeInfo.userType" placeholder="请选择您与当前住址的关系">
                                <Option v-for="item in registeredType" :value="item.dictKey" :key="item.dictKey">{{ item.dictValue }}</Option>
                            </Select>
                        </FormItem>
                    </Form>
                </template>
                <template v-slot:toolsbar>
                    <Button type="info" style="margin-right: 8px" @click="offModal">取消</Button>
                    <Button type="primary" @click="enterModify">确认</Button>
                </template>
            </LiefengModal>
            <LiefengModal title="选择房号信息" :value="addrStatus" @input="addrStatusFn" :fullscreen="false" width="30%" class="edit">
                <template v-slot:contentarea>
                    <Tree v-if="showTree" :code="treeCode" :name="treeName" @handleTree="handleTree"></Tree>
                </template>
                <template v-slot:toolsbar>
                    <Button
                        style="margin: 0 5px 0 13px"
                        type="warning"
                        @click="
                            addrTip = true
                            addrStatusFn(false)
                        "
                    >
                        取消
                    </Button>
                    <Button style="margin: 0 5px 0 13px" type="primary" @click="addrSave">保存</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
//@route("/residentindex")
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengTree from "./childrens/LiefengTree"
import LiefengModal from "@/components/LiefengModal"
import Tree from "./childrens/tree"
export default {
    watch: {
        selectQuery(newV) {
            if (!newV) {
                this.searchData.status = ""
                this.searchData.name = ""
                this.searchData.mobile = ""
                this.searchData.userType = ""
                this.searchData.detailAddr = ""
                this.searchData.contact = ""
            }
        },
    },
    data() {
        return {
            //脱敏
            hasSensitive: true,
            detailModal: false,

            userHomeInfo: {}, //居民信息
            idType: [], //证件类型
            publishList: [], //所属社区
            registeredType: [], // 与住址关系

            handleNum: 0,

            treeCode: "",
            treeName: "",
            addrStatus: false,
            showTree: false,
            addrTip: false,
            isBlock: false,

            num: 0,
            tableSelectArr: [], //table选中的
            tableSelectDataId: "", //table选中的

            statusList: [
                { label: "全部", value: 0 },
                { label: "待审核", value: "1" },
                { label: "已通过", value: "2" },
                { label: "已驳回", value: "3" },
                { label: "待确认", value: "4" },
            ],
            changeNum: 0,
            // 查询更多
            selectQuery: false,
            loading: false,
            searchData: {
                status: "1",
                name: "",
                phone: "",
                pageSize: 20,
                page: 1,
            },
            total: 0,
            communityCode: "",
            // status: 1,
            // inviterFlag: 1, //1 居民 2 成员
            treeParams: {
                // inviterFlag: 1, //1 居民 2 成员
                status: "", //必填
                roadCode: "",
                projectCode: "",
                communityCode: "", //必填
                pageSize: 20,
                page: 1,
                name: "",
                phone: "",
                oemCode: "", //必填  缓存拿
                // buildingCode:'',
                // houseNo:""
            },
            treeData: [],
            talbeColumns: [
                {
                    type: "selection",
                    width: 60,
                    align: "center",
                },
                {
                    title: "姓名",
                    key: "name",
                    align: "center",
                    width: 100,
                },
                {
                    title: "性别",
                    // key: "sex",
                    align: "center",
                    width: 80,
                    render: (h, params) => {
                        return h("div", {}, params.row.sex == 0 ? "未知" : params.row.sex == 1 ? "男" : params.row.sex == 2 ? "女" : "")
                    },
                },
                {
                    title: "手机号码1",
                    key: "phone",
                    width: 130,
                    align: "center",
                },
                {
                    title: "与住址关系",
                    key: "userType",
                    align: "center",
                    width: 150,
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "span",
                                {
                                    style: {
                                        display: "inline-block",
                                        width: "100%",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                    },
                                    domProps: {
                                        title: params.row.userType,
                                    },
                                    on: {
                                        click: e => {
                                            e.stopPropagation()
                                        },
                                    },
                                },
                                params.row.userType == 1
                                    ? "仅户籍在此"
                                    : params.row.userType == 2
                                    ? "是业主但户籍不在此"
                                    : params.row.userType == 3
                                    ? "是业主且户籍在此"
                                    : params.row.userType == 4
                                    ? "租赁人员"
                                    : params.row.userType == 5
                                    ? "其他"
                                    : ""
                            ),
                        ])
                    },
                },
                {
                    title: "出生日期",
                    key: "birthday",
                    width: 120,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.birthday ? this.$core.formatDate(new Date(params.row.birthday), "yyyy-MM-dd") : "")
                    },
                },
                // {
                //   title: "街巷",
                //   key: "roadName",
                //   align: "center",
                //   width: 100
                // },
                {
                    title: "所在详细区域",
                    // key: "regionWholeAddr",
                    align: "center",
                    minWidth: 250,
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "span",
                                {
                                    // style: {
                                    //   display: "inline-block",
                                    //   width: "100%",
                                    //   overflow: "hidden",
                                    //   textOverflow: "ellipsis",
                                    //   whiteSpace: "nowrap",
                                    // },
                                    domProps: {
                                        title: params.row.addr,
                                    },
                                    on: {
                                        click: e => {
                                            e.stopPropagation()
                                        },
                                    },
                                },
                                params.row.addr
                            ),
                        ])
                    },
                },
                {
                    title: "是否家庭户代表",
                    key: "contact",
                    align: "center",
                    width: 150,
                    render: (h, params) => {
                        return h("div", {}, params.row.contact == 1 ? "是" : params.row.contact == 2 ? "否" : "")
                    },
                },
                {
                    title: "与填写人关系",
                    align: "center",
                    width: 120,
                    render: (h, params) => {
                        return h(
                            "div",
                            {},
                            params.row.inviteInfoVo
                                ? this.inviterRelationshipList.map(item => {
                                      if (item.dictKey == params.row.InviteInfoVo.inviterRelationship) {
                                          return item.dictValue
                                      }
                                  })
                                : "本人"
                        )
                    },
                },
                {
                    title: "状态",
                    key: "status",
                    align: "center",
                    width: 100,
                    renderHeader: (h, params) => {
                        return h("div", [
                            h("strong", "状态"),
                            h("Icon", {
                                props: {
                                    type: "md-apps",
                                },
                                style: {
                                    marginLeft: "5px",
                                },
                                on: {
                                    click: e => {
                                        e.stopPropagation()
                                        this.isBlock = !this.isBlock
                                    },
                                },
                            }),
                            h(
                                "div",
                                {
                                    style: {
                                        marginTop: "15px",
                                        marginLeft: "-80px",
                                        // transform:'translateY(150)',
                                        // transform:'translateX(300)',
                                        width: "250px",
                                        display: this.isBlock ? "block" : "none",
                                        position: "fixed",
                                        background: "white",
                                        border: "1px solid #ccc",
                                        textAlign: "left",
                                        padding: "10px",
                                        transition: "all 1s",
                                        cursor: "pointer",
                                        userSelect: "none",
                                        zIndex: 9999,
                                    },
                                    on: {
                                        click: e => {
                                            e.stopPropagation()
                                        },
                                    },
                                },
                                [
                                    <div>
                                        <p style="text-align:center;margin-bottom:10px">网上家园居民信息状态说明</p>
                                        <p>已通过：居民入驻网上家园成功</p>
                                        <p>待审核：居民选择了详情地址并完善了身份信息，如：姓名、昵称、身份类型（与住址的关系），等待居委审核该信息的真实情况</p>
                                        <p>已驳回：居委驳回了此条信息，等待居民重新修改提交</p>
                                        <p>待确认：居民只选择了详情住址，未完善身份信息</p>
                                    </div>,
                                ]
                            ),
                        ])
                    },
                    render: (h, params) => {
                        return h("div", {}, params.row.status == 1 ? "待审核" : params.row.status == 2 ? "已通过" : params.row.status == 3 ? "已驳回" : params.row.status == 3 ? "待确认" : "")
                    },
                },
                {
                    fixed: "right",
                    title: "操作",
                    slot: "action",
                    width: 130,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Dropdown",
                                {
                                    props: {
                                        transfer: true,
                                    },
                                },
                                [
                                    h(
                                        "Button",
                                        {
                                            props: {
                                                type: "info",
                                                size: "small",
                                                icon: "ios-arrow-down",
                                            },
                                        },
                                        "编辑"
                                    ),
                                    h(
                                        "DropdownMenu",
                                        {
                                            slot: "list",
                                        },
                                        [
                                            h(
                                                "DropdownItem",
                                                {
                                                    style: {
                                                        textAlign: "center",
                                                        display: params.row.status == 1 ? "block" : "none",
                                                    },

                                                    nativeOn: {
                                                        click: () => {
                                                            this.througth(params.row)
                                                        },
                                                    },
                                                },
                                                "通过"
                                            ),

                                            h(
                                                "DropdownItem",
                                                {
                                                    style: {
                                                        textAlign: "center",
                                                        display: params.row.status == 1 ? "block" : "none",
                                                    },
                                                    nativeOn: {
                                                        click: () => {
                                                            this.reject(params.row)
                                                        },
                                                    },
                                                },
                                                "驳回"
                                            ),

                                            h(
                                                "DropdownItem",
                                                {
                                                    style: {
                                                        textAlign: "center",
                                                        display: params.row.status == 3 ? "block" : "none",
                                                    },
                                                    nativeOn: {
                                                        click: () => {
                                                            this.$Modal.info({
                                                                title: "驳回原因",
                                                                content: params.row.reason,
                                                            })
                                                        },
                                                    },
                                                },
                                                "查看驳回原因"
                                            ),
                                            h(
                                                "DropdownItem",
                                                {
                                                    style: {
                                                        textAlign: "center",
                                                        display: params.row.status == 1 ? "block" : "none",
                                                    },
                                                    nativeOn: {
                                                        click: () => {
                                                            this.userHomeInfo = {}
                                                            this.detailModalFn(true)
                                                            this.$get("/syaa/pc/sy/user/online/home/selectAuthUserHomeInfo", {
                                                                memberId: params.row.familyMemberId,
                                                            }).then(res => {
                                                                if (res.code == 200) {
                                                                    this.userHomeInfo = res.data
                                                                } else {
                                                                    this.$Message.error({
                                                                        content: "获取居民认证信息失败",
                                                                        background: true,
                                                                    })
                                                                }
                                                            })
                                                        },
                                                    },
                                                },
                                                "修改"
                                            ),
                                            h(
                                                "DropdownItem",
                                                {
                                                    style: {
                                                        textAlign: "center",
                                                    },
                                                    nativeOn: {
                                                        click: () => {
                                                            this.remove(params.row)
                                                        },
                                                    },
                                                },
                                                "删除"
                                            ),
                                        ]
                                    ),
                                ]
                            ),
                        ])
                    },
                },
            ],
            tableData: [],
            selection: [],
            showDo: true,
            hideEdit: true,
            hidenBread: true,
            hideDel: true,
            hideSearch: false,
            curPage: 1,
            pageSize: 20,
            orgCode: "",
            rejectModal: false,
            rejectParams: {},
            familyMemberId: "",
            formValidate: {
                reason: "",
            },
            ruleValidate: {
                reason: [
                    {
                        required: true,
                        message: "驳回原因不能为空",
                        trigger: "blur",
                    },
                ],
            },
            inviterRelationshipList: [],
            idNode: "",
        }
    },
    created() {
        if (this.$route.query.communityCode && this.$route.query.communityCode != "") this.idNode = this.$route.query.communityCode
        else this.idNode = ""
        this.getPulishData(4401, "publishList")
        this.getDicType("PERSONAL_TYPE", "idType")
        this.getDicType("FAMILY_RELATIONSHIP", "inviterRelationshipList")
        this.getDicType("ONLINE_HOME_USER_TYPE", "registeredType")
        // this.getDictionaries()
    },
    methods: {
        // 返回按钮事件判断
        backClick() {
            if (this.$route.query.name && !this.$route.query.isLineHome) {
                this.$router.push(`/${this.$route.query.name}`)
                return
            }
            if (this.$route.query.isLineHome && !this.$route.query.page) {
                this.$router.push(`/residentdatabase`)
                return
            }
            if (this.$route.query.isLineHome && this.$route.query.page) {
                this.$router.push(`/linehomeindex?isLineHome=${this.$route.query.isLineHome}&page=${this.$route.query.page}`)
                return
            }
        },
        gethandleNum(orgcode) {
            this.$get("/syaa/pc/sy/user/online/home/selectFamilyMemberReplenishRooNo", {
                operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: orgcode,
                oemCode: parent.vue.oemInfo.oemCode,
            }).then(res => {
                if (res.code == 200) this.handleNum = res.data
            })
        },
        goSupplement() {
            console.log(234)
            this.$router.push({
                path: "/supplement?name=linehomeindex&id=",
                query: { communityCode: this.communityCode, isLineHome: this.$route.query.isLineHome ? this.$route.query.isLineHome : "", page: this.$route.query.page ? this.$route.query.page : "" },
            })
        },
        loadCommunity(item, callback) {
            console.log(item.value, callback)
            item.loading = true
            let arrCode = item.value
            console.log(arrCode)
            this.$get("/gx/pc/organization/selectOrganizationByOrgCode", {
                orgCode: arrCode,
            }).then(res => {
                if (res.code == 200) {
                    if (!item.index) {
                        item.children = res.dataList.map(item => {
                            return {
                                value: item.orgCode,
                                label: item.orgName,
                                orgLevel: item.orgLevel,
                                loading: false,
                                children: [],
                                index: 1,
                            }
                        })
                        if (this.formData.changeOrgArr && this.formData.changeOrgArr.length > 0) {
                            this.formData.orgCode = this.formData.orgCode.concat(this.formData.changeOrgArr[0])
                            this.formData.changeOrgArr = this.formData.changeOrgArr.slice(1, this.formData.changeOrgArr.length)
                        }
                    } else {
                        item.children = res.dataList.map(item => {
                            return {
                                value: item.orgCode,
                                label: item.orgName,
                                orgLevel: item.orgLevel,
                                index: 1,
                            }
                        })
                        if (this.formData.changeOrgArr && this.formData.changeOrgArr.length > 0) {
                            this.formData.orgCode = this.formData.orgCode.concat(this.formData.changeOrgArr[0])
                            this.formData.changeOrgArr = this.formData.changeOrgArr.slice(1, this.formData.changeOrgArr.length)
                        }
                    }
                    item.loading = false
                    callback()
                }
            })
        },
        detailModalFn(status) {
            this.detailModal = status
        },
        getPulishData(code, list) {
            this.$get("/gx/pc/organization/selectOrganizationByOrgCode", {
                orgCode: code,
            }).then(res => {
                if (res.code == 200) {
                    this[list] = res.dataList.map(item => {
                        return {
                            value: item.orgCode,
                            label: item.orgName,
                            orgLevel: item.orgLevel,
                            loading: false,
                            children: [],
                        }
                    })
                }
            })
        },
        // 获取字典接口的公共方法
        getDicType(type, model) {
            this.$get("/voteapi/api/common/sys/findDictByDictType", {
                dictType: type,
            }).then(res => {
                this[model] = res.dataList
            })
        },
        // 保存房号
        addrStatusFn(status) {
            if (!status && this.addrTip) {
                this.addrStatus = status
                this.showTree = false
                this.addrTip = false
                this.treeCode = ""
            } else {
                this.addrStatus = status
                this.showTree = false
                this.addrTip = false
            }
        },
        // 点击了树节点的东西
        handleTree(val, treeData) {
            this.selectTree = val
            this.treeData = []
            this.treeData = treeData
            this.address = ""
            this.getCurrentTitle(this.selectTree[0].id, treeData)
        },
        getCurrentTitle(id, treeData) {
            for (let k in treeData) {
                if (treeData[k].id == id) {
                    this.address = (treeData[k].name || treeData[k].orgName) + this.address
                    if (!treeData[k].parentId) return
                    this.getCurrentTitle(treeData[k].parentId, this.treeData)
                } else if (treeData[k].children && treeData[k].children.length > 0) {
                    this.getCurrentTitle(id, treeData[k].children)
                }
            }
        },
        offModal() {
            this.detailModalFn(false)
        },
        // 树结构保存按钮
        addrSave() {
            if (this.selectTree && this.selectTree.length == 0) {
                this.$Message.warning({
                    content: "请选择房号信息",
                    background: true,
                })
                return
            } else {
                if (this.selectTree[0].regionType != "0" && this.selectTree[0].regionType != "4") {
                    this.$Message.warning({
                        content: "现住地址请选择到房号",
                        background: true,
                    })
                    return
                } else {
                    this.userHomeInfo.addr = this.address
                    this.userHomeInfo.houseCode = this.selectTree[0].code
                    this.addrStatus = false
                    this.showTree = false
                    this.addrTip = false
                }
            }
        },
        // 点击其他地方
        changeOther() {
            if (this.isBlock) {
                this.isBlock = !this.isBlock
            }
        },
        // 修改信息
        enterModify() {
            console.log(this.userHomeInfo)
            if (!this.userHomeInfo.name.trim()) {
                this.$Message.warning({
                    content: "请输入您的真实姓名",
                    background: true,
                })
                return
            }
            if (!this.userHomeInfo.houseCode) {
                this.$Message.warning({
                    content: "请选择现住址",
                    background: true,
                })
                return
            }
            if (!this.userHomeInfo.userType) {
                this.$Message.warning({
                    content: "请选择您与当前住址关系",
                    background: true,
                })
                return
            }
            let userHomeInfo = {
                name: this.userHomeInfo.name,
                houseCode: this.userHomeInfo.houseCode,
                userType: this.userHomeInfo.userType,
                orgCode: this.userHomeInfo.orgCode,
                custGlobalId: this.userHomeInfo.custGlobalId,
                familyMemberId: this.userHomeInfo.familyMemberId,
                oemCode: parent.vue.oemInfo.oemCde,
            }
            this.$post("/syaa/pc/sy/user/online/home/updateOnlineHome", {
                ...userHomeInfo,
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "修改成功",
                        background: true,
                    })
                    this.detailModalFn(false)
                    this.getList()
                } else {
                    this.$Message.error({
                        content: "修改失败",
                        background: true,
                    })
                }
            })
        },
        // 批量审核
        througthMore() {
            if (!this.num) {
                this.$Message.warning({
                    content: "请勾选数据",
                    background: true,
                })
            } else {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "确认通过该数据？",
                    onOk: () => {
                        let params = {
                            familyMemberId: this.tableSelectDataId,
                            reviewId: parent.vue.loginInfo.userinfo.custGlobalId,
                            name: parent.vue.loginInfo.userinfo.nickName,
                            status: 2,
                        }
                        this.$post("/syaa/pc/sy/user/online/home/changeSyFamilyMember", params).then(res => {
                            if (res.code == "200") {
                                this.$Message.success("操作成功")
                                let data = {
                                    pageSize: this.pageSize,
                                    page: this.curPage,
                                }
                                this.num = 0
                                this.getList(data)
                            } else {
                                this.$Message.error("操作失败")
                            }
                        })
                    },
                })
            }
        },

        tableSelect(data) {
            //表格选中
            console.log(data)
            this.num = data.length
            let tableSelectDataId = []
            if (data.length != 0) {
                data.forEach(res => {
                    tableSelectDataId.push(res.familyMemberId)
                })
            }
            this.tableSelectDataId = tableSelectDataId.join()
        },

        //导出excel
        exportExcel() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>您正在导出当前页的数据，是否继续？（若要导出更多数据，请设置每页条数。）</p>",
                onOk: () => {
                    let tHeader = ["姓名", "性别", "联系电话", "身份类型", "出生日期", "街巷", "所在详细区域", "是否家庭户代表"]
                    let filterVal = ["name", "sexLabel", "phone", "userTypeLabel", "birthdayValue", "roadName", "detailAddr", "contactLabel"]
                    this.$core.exportExcel(tHeader, filterVal, this.tableData, "居民审核列表")
                },
            })
        },
        //驳回框事件
        rejectModalFn(status) {
            this.rejectModal = status
        },
        //
        rejectEvent(name) {
            this.$refs[name].validate(valid => {
                if (valid) {
                    this.rejectParams = {
                        familyMemberId: this.familyMemberId,
                        reviewId: parent.vue.loginInfo.userinfo.custGlobalId,
                        name: parent.vue.loginInfo.userinfo.nickName,
                        status: 3,
                        result: this.formValidate.reason,
                    }
                    this.$post("/syaa/pc/sy/user/online/home/changeSyFamilyMember", this.rejectParams)
                        .then(res => {
                            if (res.code == "200") {
                                this.$Message.success("操作成功")
                                let data = {
                                    pageSize: this.pageSize,
                                    page: this.curPage,
                                }
                                this.getList(data)
                                this.rejectModal = false
                                this.formValidate.reason = ""
                            } else {
                                this.$Message.error("操作失败！")
                            }
                        })
                        .catch(err => {
                            this.$Message.error("操作失败！")
                        })
                }
            })
        },
        reject(data) {
            if (data) {
                // 为了在删除最后一页的最后一条数据时能成功跳转回最后一页的上一页
                let totalPage = Math.ceil((this.total - 1) / this.pageSize) // 总页数
                this.curPage = this.curPage > totalPage ? totalPage : this.curPage
                this.curPage = this.curPage < 1 ? 1 : this.curPage
                this.rejectModal = true
                this.familyMemberId = data.familyMemberId
            } else {
                this.$Message.error("请先选择数据")
            }
        },
        // 删除
        remove(row) {
            if (row) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "<p>确认删除数据</p>",
                    cancelText: "取消",
                    okText: "确认",
                    onOk: () => {
                        // 为了在删除最后一页的最后一条数据时能成功跳转回最后一页的上一页
                        let totalPage = Math.ceil((this.total - 1) / this.pageSize) // 总页数
                        this.curPage = this.curPage > totalPage ? totalPage : this.curPage
                        this.curPage = this.curPage < 1 ? 1 : this.curPage

                        this.$post("/syaa/pc/sy/user/online/home/delFamilyMember", {
                            familyMemberId: row.familyMemberId,
                        })
                            .then(res => {
                                if (res.code === "200") {
                                    this.$Message.success("删除成功")
                                    let data = {
                                        pageSize: this.pageSize,
                                        page: this.curPage,
                                    }
                                    this.getList(data)
                                } else {
                                    this.$Message.error("删除失败！")
                                }
                            })
                            .catch(err => this.$Message.error("删除失败！"))
                    },
                })
            } else {
                this.$Message.error("请先选择数据")
            }
        },
        searchRoom() {
            if (!this.userHomeInfo.orgCode) {
                this.$Message.warning({
                    content: "请先选择所属社区",
                    background: true,
                })
                return
            }

            this.treeCode = ""
            this.treeCode = this.userHomeInfo.orgCode
            this.addrStatus = true
            this.$nextTick(() => {
                this.showTree = true
            })
        },
        changeHouseAddr() {
            this.userHomeInfo.houseCode = ""
        },
        //通过
        througth(data) {
            let totalPage = Math.ceil((this.total - 1) / this.pageSize) // 总页数
            this.curPage = this.curPage > totalPage ? totalPage : this.curPage
            this.curPage = this.curPage < 1 ? 1 : this.curPage
            if (data) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "确认通过该数据？",
                    onOk: () => {
                        let params = {
                            familyMemberId: data.familyMemberId,
                            reviewId: parent.vue.loginInfo.userinfo.custGlobalId,
                            name: parent.vue.loginInfo.userinfo.nickName,
                            status: 2,
                        }
                        // 为了在删除最后一页的最后一条数据时能成功跳转回最后一页的上一页
                        let totalPage = Math.ceil((this.total - 1) / this.pageSize) // 总页数
                        this.curPage = this.curPage > totalPage ? totalPage : this.curPage
                        this.curPage = this.curPage < 1 ? 1 : this.curPage
                        this.$post("/syaa/pc/sy/user/online/home/changeSyFamilyMember", params)
                            .then(res => {
                                if (res.code == "200") {
                                    this.$Message.success("操作成功")
                                    let data = {
                                        pageSize: this.pageSize,
                                        page: this.curPage,
                                    }
                                    this.getList(data)
                                } else {
                                    this.$Message.error("操作失败")
                                }
                            })
                            .catch(err => {
                                this.$Message.error("操作失败")
                            })
                    },
                })
            }
        },
        //重置
        rest() {
            this.searchData.mobile = ""
            this.searchData.status = ""
            this.searchData.name = ""
            this.searchData.userType = ""
            this.searchData.detailAddr = ""
            this.searchData.contact = ""
            this.curPage = 1
            this.pageSize = 20
            this.getList()
        },
        //page页面改变
        hadlePageSize(obj) {
            this.getList(obj)
        },
        //菜单树
        getList(data) {
            // if(this.searchData.status == '0')
            // this.searchData.status = ''
            console.log(data)
            this.loading = true
            if (data) {
                // if(this.$route.query.code && this.changeNum == 0){
                //   this.changeNum++
                //   data.communityCode = this.$route.query.code
                // }
                //第一次进来页面就会执行
                if (data.communityCode) {
                    // this.searchData.status = "1";
                    // this.searchData.name = "";
                    // this.searchData.mobile = "";
                    this.orgCode = parent.vue.loginInfo.userinfo.orgCode
                    this.tableData = []
                    this.treeParams = {
                        ...data,
                        communityCode: data.communityCode,
                        status: this.searchData.status ? this.searchData.status : "", //必填
                        name: this.searchData.name,
                        mobile: this.searchData.mobile,
                        oemCode: parent.vue.loginInfo.userinfo.oemCodes[0],
                        page: data.page,
                        pageSize: data.pageSize,
                        buildingCode: "",
                        houseNo: "",
                        // inviterFlag: 1,
                    }
                    this.curPage = data.page
                    this.pageSize = data.pageSize
                }
                if (data.page) {
                    if (data.regionType == "2" || data.regionType == "3") {
                        this.treeParams.projectCode = ""
                        this.treeParams.buildingCode = data.projectCode
                    }
                    if (data.regionType == 0 || data.regionType == 4) {
                        // console.log('如果为0 4进',data);
                        this.treeParams.projectCode = ""
                        this.treeParams.houseNo = data.projectCode
                    }
                    //切换页面时
                    // this.treeParams.inviterFlag = 1;
                    this.curPage = data.page
                    this.pageSize = data.pageSize
                    this.treeParams.page = data.page
                    this.treeParams.pageSize = data.pageSize
                    this.treeParams.status = this.searchData.status ? this.searchData.status : ""
                    this.treeParams.name = this.searchData.name
                    this.treeParams.mobile = this.searchData.mobile
                    this.treeParams.userType = this.searchData.userType
                    this.treeParams.detailAddr = this.searchData.detailAddr
                    this.treeParams.contact = this.searchData.contact
                }
                if (data.cancelable) {
                    //点击查询时  查询返回第一页的数据
                    this.treeParams.page = 1
                    // this.treeParams.inviterFlag = 1;

                    this.treeParams.name = this.searchData.name
                    this.treeParams.mobile = this.searchData.mobile
                    this.treeParams.status = this.searchData.status ? this.searchData.status : ""
                    this.treeParams.userType = this.searchData.userType
                    this.treeParams.detailAddr = this.searchData.detailAddr
                    this.treeParams.contact = this.searchData.contact
                    this.curPage = 1
                }
            } else {
                //重置的时候执行
                this.treeParams = {
                    communityCode: this.treeParams.communityCode,
                    roadCode: this.treeParams.roadCode,
                    status: this.searchData.status ? this.searchData.status : "", //必填
                    name: this.searchData.name,
                    mobile: this.searchData.mobile,
                    userType: this.searchData.userType,
                    detailAddr: this.searchData.detailAddr,
                    contact: this.searchData.contact,
                    oemCode: parent.vue.loginInfo.userinfo.oemCodes[0],
                    page: this.curPage,
                    pageSize: this.pageSize,
                    // inviterFlag: 1, //1 居民 2 成员
                }
            }
            //请求页面表格数据
            this.communityCode = this.treeParams.communityCode
            this.gethandleNum(this.treeParams.communityCode)
            this.$get("/syaa/pc/sy/user/online/home/selectSyFamilyMemberPage", {
                ...this.treeParams,
                orgCode: this.orgCode,
                hasSensitive: this.hasSensitive,
                operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            })
                .then(res => {
                    if (res.code === "200") {
                        this.tableData = res.dataList
                        this.total = res.maxCount
                        this.curPage = res.currentPage
                        // this.treeParams.name = "";
                        // this.treeParams.mobile = "";
                        this.loading = false
                        this.selectQuery = false
                        //  this.searchData.name = '';
                        // this.searchData.mobile = '';
                        this.searchData.userType = ""
                        this.searchData.detailAddr = ""
                        this.searchData.contact = ""
                    } else {
                        this.$Message.error({
                            background: true,
                            content: "获取数据失败！",
                        })
                        this.loading = false
                    }
                })
                .catch(err => {
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败！",
                    })
                    this.loading = false
                })
        },
        changeEye() {
            this.hasSensitive = !this.hasSensitive
            this.getList()
        },
    },
    components: {
        LiefengContent,
        LiefengModal,
        LiefengTable,
        LiefengTree,
        Tree,
    },
}
</script>

<style scoped lang="less">
/deep/.ivu-drawer-body {
    .ivu-form-item {
        width: 100%;
        margin-bottom: 0;
    }
}
.demo-drawer-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
}
/deep/.ivu-form-item-label {
    width: 120px !important;
}
/deep/.ivu-form-item-content {
    margin-left: 120px !important;
}
/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.search {
    /deep/.ivu-form-item-content {
        margin-left: 0 !important;
    }
}
.eye {
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    margin: 0 15px 0;
}
/deep/#contentarea {
    position: absolute;
    left:0 !important;
}
/deep/.aside{
    top: 0 !important;
}
.table-div{
    margin-left: 200px;
}
//  ivu-select-single ivu-select-default
</style>
